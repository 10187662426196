import React from "react";
import NavBar from "../NavBar/NavBar";
import { motion } from "framer-motion";
import "./vision.css";

// Media
import disconnected from "../../media/Vision/disconnected-min.svg";
import peterTsemberides from "../../media/Team/PeterTsemberides.webp";
import matthewIncardona from "../../media/Team/MatthewIncardona.webp";
import apps from "../../media/Vision/Appstore.webp";

const Vision = () => {
  return (
    <motion.div
      initial={{ opacity: 0.4 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.2 }}
      transition={{ ease: "easeOut", duration: 0.3 }}
    >
      <NavBar />
      <div
        initial={{ opacity: 0.4 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.2 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
        className="wrapper"
      >
        <section>
          <div className="advert margin--large">
            <div className="card advert__text">
              <h2>
                There's a Disconnect Between Students and Their Educational
                Software
              </h2>
              <p>
                While in college, we saw a constant need for software outside
                the classroom. Students couldn't bring programs home with them,
                and those on Chromebooks or without their own computer had to
                make due with school computers.
              </p>
            </div>
            <div className="advert__img">
              <img src={disconnected} alt="" />
            </div>
          </div>
          <div className="advert reverse margin--large">
            <div className="advert__img">
              <img src={apps} alt="" />
            </div>
            <div className="card advert__text">
              <h2>And We're Going to Fix It</h2>
              <p>
                Using innovative cloud computing tech, we help you run any
                popular software from anywhere. From students to remote
                consultants, we enable you to run the programs you need, when
                you need them.
              </p>
            </div>
          </div>
          <div className="card vision margin--large">
            <h2>Our Vision</h2>
            <p>
              To create a world where anyone can access powerful programs to
              learn and create with any device.
            </p>
          </div>
        </section>

        <section>
          <div className="team padding--small">
            <h3 className="team__title">
              You’ve met the product, now meet us! We are a team of college
              students that wanted to create a concrete solution to a problem we
              saw.
            </h3>
            <div className="team__wrapper">
              <div className="team__card card margin--large">
                <img src={peterTsemberides} className="team__img" alt="" />
                <p className="team__descr">
                  The <b>CMO</b> of this budding team is Peter Tsemberides, a
                  trained social media, marketing, and research consultant.
                </p>
              </div>
              <div className="team__card card margin--large">
                <img src={matthewIncardona} className="team__img" alt="" />
                <p className="team__descr">
                  Covering all aspects of product development, our <b>CEO</b>{" "}
                  and Lead Developer is the backbone of Second Desk.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </motion.div>
  );
};

export default Vision;
