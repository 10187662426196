import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { auth, sendUserPasswordResetEmail } from "../../firebase";
import { motion } from "framer-motion";
import NavBar from "../NavBar/NavBar";
import "./Reset.css";
import "./Auth.css";

function Reset() {
  const [email, setEmail] = useState("");
  //   const [user, loading, error] = useAuthState(auth);
  const [user, loading] = useAuthState(auth);
  const history = useHistory();
  useEffect(() => {
    if (loading) return;
    if (user) history.replace("/dashboard");
  }, [user, loading, history]);
  return (
    <motion.div
      initial={{ opacity: 0.4 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.2 }}
      transition={{ ease: "easeOut", duration: 0.3 }}
      className="register__wrapper"
    >
      <header>
        <NavBar />
      </header>
      <div className="authForm">
        <div className="authForm__container borderGradient borderGradient--purple">
          <h4 className="register__header">Create a New Account</h4>
          <label className="authForm__label" htmlFor="email-input">
            Email
          </label>
          <input
            id="email-input"
            type="text"
            className="authForm__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />
          <button
            className="authForm__btn authForm__submit"
            onClick={() => sendUserPasswordResetEmail(email)}
          >
            Send password reset email
          </button>
          <div className="authForm__footer">
            <p>
              Don't have an account? <Link to="/register">Register now</Link>
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
export default Reset;
