import React from "react";
import { Link } from "react-router-dom";
import "./navBar.css";
import "../../index.css";

// Media
import logo from "../../media/Logo/Logo.png";
import arrowDown from "../../media/NavBar/arrowDown.svg";

function NavBar() {
  return (
    <div>
      <div className="desktopMenu nav__wrapper">
        <nav role="navigation" className="nav drop-shadow">
          <div className="nav__linkContainer">
            <Link className to={"/"}>
              <img src={logo} alt="" className="nav__logo" />
            </Link>
            <ul className="nav__links">
              <li className="nav__item nav__item--light">
                <a href="https://sd-local-client.pages.dev/">Demo</a>
              </li>
              <li className="nav__item nav__item--light">
                <Link className to={"/pricing"}>
                  Pricing
                </Link>
              </li>
              <li className="nav__item nav__item--light">
                <Link className to={"/about-us"}>
                  Our Vision
                </Link>
              </li>
              <li className="nav__authContainer">
                <li className="nav__item nav__login">
                  <Link className to={"/login"}>
                    Log in
                  </Link>
                </li>
                <li className="nav__item nav__signUp">
                  <Link className to={"/register"}>
                    Sign up
                  </Link>
                  <img src={arrowDown} alt="" className="nav__caret" />
                </li>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {/* Mobile Menu */}
      <nav role="navigation" className="mobileMenu">
        <div id="menuToggle">
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu">
            <li className="nav__item nav__item--light">
              <a href="https://sd-local-client.pages.dev/">Demo</a>
            </li>
            <li className="nav__item nav__item--light">
              <a href="/pricing">Pricing</a>
            </li>
            <li className="nav__item nav__item--light">
              <a href="/about-us">Our Vision</a>
            </li>
            <li className="nav__authContainer">
              <li className="nav__item nav__login">
                <Link className to={"/login"}>
                  Log in
                </Link>
              </li>
              <li className="nav__item nav__signUp">
                <Link className to={"/register"}>
                  Sign up
                </Link>
                <img src={arrowDown} alt="" className="nav__caret" />
              </li>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
