import React, { useContext } from "react";
import { ProgramContext } from "../contexts/ProgramContext";
import ProgramWindow from "../Desktop/ProgramWindow/ProgramWindow";
import "../../index.css";

const ProgramSlider = () => {
  const { RUNNING_PROGRAMS } = useContext(ProgramContext);

  return (
    <div className="program-window">
      <div>
        {RUNNING_PROGRAMS.map((programItem) => (
          <ProgramWindow
            name={programItem.name}
            url={programItem.url}
            key={programItem.name}
          />
        ))}
      </div>
    </div>
  );
};

export default ProgramSlider;
