import React from "react";
import "./Anna.css";

const Anna = () => {
  return (
    <div className="connect__container">
      <div className="connect connect--blue">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://network.seconddesk.app/Myrtille/?__EVENTTARGET=&__EVENTARGUMENT=&server=localhost&user=Administrator&passwordHash=01000000D08C9DDF0115D1118C7A00C04FC297EB0100000004ED7B6018053A4A9C59229AB74C1D53040000000200000000001066000000010000200000002104A7382791EFE69A2055AA9E74435FF64EA7B1484CBABA74870720B9CA75B9000000000E8000000002000020000000763446BC4D16CE847205C0991C56A4963E130BD17A7AF76759CCA8B7C21DBE9C300000007D828D347730173E21B21A4A8F0D57339207ED499C26DB02CB8F1D9A6EBB1414DBB021143F3752456086B637D733455340000000081AD60057230E001B2ABBC0DD5AFB8B3E9A9B18A2BE24918628BD1C6C0F25A70AD6CD10E27298F7B87DCC23B7D90CADDC41B7B106FE4ACE9990F93A1022B2E8&program=C%3A%5CProgram+Files%5CGoogle%5CDrive+File+Stream%5C51.0.14.0%5CGoogleDriveFS.exe&connect=Connect%21"
        >
          Google Drive
        </a>
      </div>
      <div className="connect connect--red">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://network.seconddesk.app/Myrtille/?__EVENTTARGET=&__EVENTARGUMENT=&server=localhost&user=Administrator&passwordHash=01000000D08C9DDF0115D1118C7A00C04FC297EB0100000004ED7B6018053A4A9C59229AB74C1D53040000000200000000001066000000010000200000002104A7382791EFE69A2055AA9E74435FF64EA7B1484CBABA74870720B9CA75B9000000000E8000000002000020000000763446BC4D16CE847205C0991C56A4963E130BD17A7AF76759CCA8B7C21DBE9C300000007D828D347730173E21B21A4A8F0D57339207ED499C26DB02CB8F1D9A6EBB1414DBB021143F3752456086B637D733455340000000081AD60057230E001B2ABBC0DD5AFB8B3E9A9B18A2BE24918628BD1C6C0F25A70AD6CD10E27298F7B87DCC23B7D90CADDC41B7B106FE4ACE9990F93A1022B2E8&program=C%3A%5CProgram+Files%5CCakewalk%5CCakewalk+Core%5CCakewalk.exe&connect=Connect%21"
        >
          Cakewalk
        </a>
      </div>
      <div className="connect connect--green">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://network.seconddesk.app/Myrtille/?__EVENTTARGET=&__EVENTARGUMENT=&server=localhost&user=Administrator&passwordHash=01000000D08C9DDF0115D1118C7A00C04FC297EB0100000004ED7B6018053A4A9C59229AB74C1D5304000000020000000000106600000001000020000000B8FC610C27FFA5F67B5A7CE47269D3270E925FF1EBC7CA4B5CE3F49A7CA46129000000000E800000000200002000000054F886670079A2FEC1AA12B77C1B9871543438A5F0EDB7EF464150092CFD8D1C5000000086F938CA23C311CCFD1E34FFAC3559F3371F6BD567123CD32F557BAE8F4209BCD7D6F2D2E4D76588CBB0520387EC2BF3762095A9F34F19977F1AE173041EF1C0770310CB0DAA89942BA6702AA8C3D4284000000021C965093F8E044AE849FCA18584E8DD89BE3D29865E021465301BE6DFBFD9E247A33D4938117FDA2D07C42C6BAF652BA723E1EDFF77A340B93B6FE555AFBCD8&connect=Connect%21"
        >
          Connect to Desktop
        </a>
      </div>
      <div className="connect connect--grey">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfdIs3lPzsBwKlRCVWO-wr4yQZ8SePmbJfjOHbPuQTzbDN2gg/viewform?usp=sf_link"
        >
          Survey
        </a>
      </div>
    </div>
  );
};

export default Anna;
