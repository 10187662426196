import React from "react";
import NavBar from "../NavBar/NavBar";
import "./pricing.css";

const Pricing = () => {
  return (
    <div>
      <header>
        <NavBar />
      </header>
      <main>
        <div className="pricing">
          <p className="pricing__title">Pricing Coming Soon!</p>
        </div>
      </main>
    </div>
  );
};

export default Pricing;
