import React, { useContext } from "react";
import { ProgramContext } from "../../contexts/ProgramContext.jsx";

// Media
import developIcon from "../../../media/Desktop/developIcon.svg";
import createIcon from "../../../media/Desktop/createIcon.svg";
import animateIcon from "../../../media/Desktop/animateIcon.svg";
import designIcon from "../../../media/Desktop/designIcon.svg";

const DropdownListTemp = (props) => {
  const { programHandler } = useContext(ProgramContext);

  function eventHandler(programName) {
    programHandler(programName);
    displayHandler();
  }

  function displayHandler() {
    props.onHide();
  }

  return (
    <div>
      {props.children}
      {/* Active button */}
      <div className="program-picker--active">
        <button
          className="program-picker__btn program-picker__submit"
          onClick={() => eventHandler("Inkscape")}
        >
          <img src={designIcon} alt="" className="program-picker__drop-img" />
          <p className="program-picker__btn-text">Design</p>
        </button>
      </div>
      {/* Dropdown */}
      <div
        className={
          props.dropdownIsActive
            ? "program-picker__menu--inactive"
            : "program-picker__menu--active"
        }
      >
        <button
          className="program-picker__btn"
          onClick={() => eventHandler("GIMP")}
        >
          <img src={createIcon} alt="" className="program-picker__drop-img" />
          <p className="program-picker__btn-text">Edit</p>
        </button>
        <button
          className="program-picker__btn"
          onClick={() => eventHandler("MuseScore")}
        >
          <img src={developIcon} alt="" className="program-picker__drop-img" />
          <p className="program-picker__btn-text">Compose</p>
        </button>
        <div className="program-picker__menu">
          <button
            className="program-picker__btn"
            onClick={() => eventHandler("LibreOffice")}
          >
            <img
              src={animateIcon}
              alt=""
              className="program-picker__drop-img"
            />
            <p className="program-picker__btn-text">Write</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropdownListTemp;
