import React, { useState, useEffect } from "react";
import NavBar from "../NavBar/NavBar";
import { Link, useParams } from "react-router-dom";
import { commerce } from "../../lib/commerce";
// import stripHtml from "string-strip-html";

// Media
import backArrow from "../../media/AppStore/backArrow.svg";

const AppDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    setLoading(true); // in case of re-renders later, set it back to true
    commerce.products.retrieve(id, { type: "permalink" }).then((product) => {
      setProduct(product); // set the product in state
      setLoading(false); // turn off the loading flag
    });
  }, [id]); // run this effect once on load, then whenever the `id` changes

  if (loading) {
    return <p>Loading...</p>;
  }

  // Remove html tags from description
  const regex = /(<([^>]+)>)/gi;
  const productDescription = product.description.replace(regex, "");

  return (
    <div>
      <NavBar></NavBar>
      <div className="app-page">
        <div className="app-page__header">
          <div className="app-page__card">
            {/* back to store arrow */}
            <Link
              className="app-card__back-arrow"
              to={`/appstore`}
              style={{
                border: "none",
                textDecoration: "none",
                fontSize: "1.2rem",
              }}
            >
              <img src={backArrow} alt="" />
            </Link>

            <div className="app-card__logo-section">
              <img
                className="app-card__logo"
                src={product.media.source}
                alt=""
              />
              <p className="sub">version 1.0.23</p>
            </div>
            <div className="app-card__title-section">
              <h1 className="app-page__title">{product.name}</h1>
              <hr className="hr--left" />
              <p className="app-page__descr">{productDescription}</p>
              <button className="btn btn--blue app-page__btn">
                {`Buy Now - ${product.price.formatted_with_symbol}`}
              </button>
              <p className="sub">*Software License Included</p>
            </div>
          </div>
        </div>
        <div className="app-page__body">
          <h3 className="app-body__heading">Description</h3>
          <p className="descr">{productDescription}</p>
          <div className="app-page__body-container">
            <div className="app-page__body-row">
              <h3 className="app-body__heading">Great For</h3>
              <ul className="label-list">
                <li className="label">
                  <p>Vectoring</p>
                </li>
                <li className="label">
                  <p>Iconography</p>
                </li>
                <li className="label">
                  <p>Object Masking</p>
                </li>
              </ul>
            </div>
            <div className="app-page__body-row app-page__body-row--centered">
              <h3 className="app-body__heading">Skill Level</h3>
              <ul className="label-list">
                <li className="label label--secondary">
                  <p>Intermediate</p>
                </li>
                <li className="label label--secondary">
                  <p>Simple UI</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDetails;
