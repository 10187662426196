import React, { useContext } from "react";
import { TaskbarContext } from "../../contexts/TaskbarContext.jsx";
import { motion } from "framer-motion";
import AppStoreToggle from "./AppStoreToggle";

// Media
import browserIcon from "../../../media/Desktop/browserIcon.svg";
import folderIcon from "../../../media/Desktop/folderIcon.svg";
import notificationIcon from "../../../media/Desktop/notificationIcon.svg";

const Taskbar = (props) => {
  const { setFooterIsActive } = useContext(TaskbarContext);

  return (
    <motion.div
      className="desktop__taskbar"
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{ ease: "easeOut", duration: 0.5 }}
      onMouseEnter={() => setFooterIsActive(true)} // Keep taskbar open when hovered
      onMouseLeave={() => setFooterIsActive(false)}
    >
      {/* Animate and display the taskbar icons */}
      <div className="desktop__icons desktop__icons--animate">
        <img className="desktop__icons--item" src={browserIcon} alt="" />
        <img className="desktop__icons--item" src={folderIcon} alt="" />
        <AppStoreToggle />
        <img className="desktop__icons--item" src={notificationIcon} alt="" />
      </div>
    </motion.div>
  );
};

export default Taskbar;
