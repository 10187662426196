import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import NavBar from "./components/NavBar/NavBar";
import AppSlider from "./components/Splide/AppSlider";
import "./index.css";
import "./home.css";

// Media
import developer from "./media/HomeP/Developer-min.svg";

const Home = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0.4 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.2 }}
      transition={{ ease: "easeOut", duration: 0.3 }}
      className="hero__wrapper"
    >
      <header>
        <NavBar />
      </header>
      <div className="hero">
        <div className="welcomeContainer">
          <main>
            <div className="userGreeting borderGradient borderGradient--purple">
              <div className="userGreeting__title">
                <h1>
                  Run Your <br />
                  Favorite <br />
                  Apps, <br />
                  Anywhere
                </h1>
                <div className="sub">
                  From Adobe <br />
                  to AutoDesk
                </div>
                <div className="btn-group userGreeting__btn-container">
                  <Link className to={"/pricing"}>
                    <button className="btn btn--blue">View Plans</button>
                  </Link>
                  <a href="https://sd-local-client.pages.dev/">
                    <button className="btn btn--orange">Explore Demo</button>
                  </a>
                </div>
              </div>
              <img className="userGreeting__img" src={developer} alt="" />
            </div>
          </main>
        </div>
        <div className="appScroller">
          <AppSlider />
        </div>
      </div>
    </motion.div>
  );
};

export default Home;
