import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./App.css";

import Home from "./Home";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Reset from "./components/Auth/Reset";
import Dashboard from "./components/Auth/Dashboard";
import AppDetails from "./components/AppStore/AppDetails";
import Vision from './components/Vision/Vision';
import Desktop from "./components/Desktop/Desktop";
import AppStoreCopy from "./components/AppStore/AppStoreCopy";
import Anna from "./components/Anna/Anna";
import Pricing from './components/Pricing/Pricing';

function App() {
  return (
    <AnimatePresence exitBeforeEnter>
        <Router >
          <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/desktop" component={Desktop} />
              <Route exact path="/appstore" component={AppStoreCopy} />
              <Route exact path="/app/:id" component={AppDetails} />
              <Route exact path="/about-us" component={Vision} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/reset" component={Reset} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/anna" component={Anna} />
              <Route exact path="/pricing" component={Pricing} />
            </Switch>
        </Router>
    </AnimatePresence>
  );
}

export default App;
