import React from "react";
import AppStoreCopy from "../../AppStore/AppStoreCopy";
import { motion } from "framer-motion";

const AppDrawer = (props) => {
  return (
    <motion.div
      className="app-drawer"
      initial={{ y: 800, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 800, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <AppStoreCopy />
    </motion.div>
  );
};

export default AppDrawer;
