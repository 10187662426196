import React from "react";

const AppCard = (props) => {
  return (
    <div className={"app-card app-card__label app-card__label--" + props.label}>
      <img className="app-card__img" src={props.icon} alt="" />
      <p className="app-card__title">{props.title}</p>
    </div>
  );
};

export default AppCard;
