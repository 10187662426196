import React from "react";
import AppListCopy from "./AppListCopy";

const AppStore = () => {
  return (
    <div className="store-wrapper">
      <h2 className="store__title">Welcome to the App Store</h2>
      <p className="store__subtitle">
        Browse and download your favorite software.
      </p>
      <AppListCopy />
    </div>
  );
};

export default AppStore;
