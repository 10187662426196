import React, { useContext } from "react";
import Taskbar from "./Taskbar";
import Background from "./Background";
import ProgramSlider from "../../Splide/ProgramSlider";
import AppDrawer from "./AppDrawer";
import Footer from "./Footer";
import { TaskbarContext } from "../../contexts/TaskbarContext.jsx";
import { AnimatePresence } from "framer-motion";

const DesktopUI = (props) => {
  const { windowIsOpen, footerIsActive } = useContext(TaskbarContext);

  return (
    <div className={"desktop " + (windowIsOpen ? "desktop--blur" : "")}>
      <Background />
      <ProgramSlider />
      <AnimatePresence>{windowIsOpen && <AppDrawer />}</AnimatePresence>
      <AnimatePresence>
        {/* show taskbar if either a program is not open or if a program IS open but the footer is active (ie being hovered over) */}
        {/* {(!windowIsOpen || (windowIsOpen && footerIsActive)) && <Taskbar />} */}
        {footerIsActive && <Taskbar />}
      </AnimatePresence>
      <Footer />
    </div>
  );
};

export default DesktopUI;
