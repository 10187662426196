import React, { useState } from "react";
import "../../../index.css";
import DropdownListTemp from "./DropdownListTemp";

// Media
import caret from "../../../media/Desktop/caret.svg";

const Dropdown = (props) => {
  const [dropdownIsActive, setDropdownIsActive] = useState(true);

  function dropdownCloseHandler() {
    setDropdownIsActive(false);
  }

  function dropdownOpenHandler() {
    setDropdownIsActive(true);
  }

  function displayHandler() {
    props.onHide();
  }

  return (
    <div className="program-picker">
      {/* Dropdown caret */}
      <button
        className="program-picker__btn program-picker__drop"
        onClick={dropdownIsActive ? dropdownCloseHandler : dropdownOpenHandler}
      >
        <img src={caret} alt="" className="program-picker__caret-btn" />
      </button>
      <DropdownListTemp
        dropdownIsActive={dropdownIsActive}
        onHide={displayHandler}
      />
    </div>
  );
};

export default Dropdown;
