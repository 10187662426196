import React, { useState } from "react";
import Dropdown from "./Dropdown";

// Media

const WelcomeScreen = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  function closeHandler() {
    setModalIsOpen(false);
    props.onHide();
  }

  return (
    <div className="welcome-screen">
      <div className="welcome-screen__wrapper">
        <h2 className="welcome-screen__title">What Do You Want to Do Today?</h2>
        {modalIsOpen && <Dropdown onHide={closeHandler} />}
      </div>
      {props.children}
    </div>
  );
};

export default WelcomeScreen;
