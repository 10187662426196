import React, { useContext } from "react";
import { TaskbarContext } from "../../contexts/TaskbarContext";

// This component is used to bring elements such as the taskbar back on-screen when they're hidden
const Footer = () => {
  const { setFooterIsActive } = useContext(TaskbarContext);

  return (
    <div
      onMouseEnter={() => setFooterIsActive(true)}
      onMouseLeave={() => setFooterIsActive(false)}
      className="desktop__footer"
    ></div>
  );
};

export default Footer;
