import React, { createContext, useState } from "react";

export const ProgramContext = createContext();

const ProgramContextProvider = (props) => {
  const [programName, setProgramName] = useState("");

  function programHandler(program) {
    setProgramName(program);
  }

  // List of currently running programs
  let RUNNING_PROGRAMS = [];

  // Create url to start a specific program
  let programURL, programPath;
  let networkPath = "https://network.seconddesk.app/Myrtille/";
  let connectArgs =
    "?__EVENTTARGET=&__EVENTARGUMENT=&server=localhost&user=Administrator&passwordHash=01000000D08C9DDF0115D1118C7A00C04FC297EB010000009BAFC014C1502441B8C8EDE79FC142A7040000000200000000001066000000010000200000006ABF562F97D9361CDF356E13A59E591B9209E899225B8C784F977D0418A8451D000000000E8000000002000020000000DFB3E9C13D908F6E658D6F9BD94EF6E59F8AE1EB5962075C4A186DA874B864D8400000006D14038AC05AE656E34344B6CF1EEC4538B01C40858ECD89B1671A5ABAAD62E59610D69CADC5B2ACC7CEFF534D4EBFC279489F618FED146A853C397FEDA34BE0400000002EC2F27B86C92BE8B76C34C025EF28C2D1FBAE115FC208DEDB7E5D5791298CED94011348A6250BECD5DF54E2FF3FF3961A1EB83BB99F49836E63F873C4E93BC3&program=";
  let connectURL = "&connect=Connect%21";
  let programURLShort = networkPath + connectArgs;

  // use https://www.url-encode-decode.com to encode path urls
  switch (programName) {
    case "Inkscape":
      programPath =
        "%22C%3A%5CProgram+Files%5CInkscape%5Cbin%5Cinkscape.exe%22";
      break;
    case "LibreCAD":
      programPath =
        "%22C%3A%5CProgram+Files+%28x86%29%5CLibreCAD%5CLibreCAD.exe%22";
      break;
    case "MuseScore":
      programPath =
        "%22C%3A%5CProgram+Files%5CMuseScore+3%5Cbin%5CMuseScore3.exe%22";
      break;
    case "OpenToonz":
      programPath = "%22C%3A%5CProgram+Files%5COpenToonz%5COpenToonz.exe%22";
      break;
    case "LibreOffice":
      programPath =
        "C%3A%5CProgram+Files%5CLibreOffice%5Cprogram%5Csoffice.exe";
      break;
    case "GIMP":
      programPath = "C%3A%5CProgram+Files%5CGIMP+2%5Cbin%5Cgimp-2.10.exe";
      break;
    default:
      console.log(`Error, file path cannot be found :(`);
  }

  programURL = programURLShort + programPath + connectURL;

  // Add object to array
  const program = { name: programName, url: programURL, key: programName };
  RUNNING_PROGRAMS.unshift(program);

  return (
    <ProgramContext.Provider
      value={{
        programHandler: programHandler,
        programURL,
        RUNNING_PROGRAMS,
      }}
    >
      {props.children}
    </ProgramContext.Provider>
  );
};

export default ProgramContextProvider;
