import React from "react";
import "../../index.css";
import "./appSlider.css";

// https://splidejs.com/integration-react-splide/
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";

// Media
import affinity from "../../media/AppIcons/Affinity.png";
import inkscape from "../../media/AppIcons/Inkscape.png";
import illustrator from "../../media/AppIcons/Illustrator.png";

import musescore from "../../media/AppIcons/Musescore.png";
import flStudio from "../../media/AppIcons/FLStudio.png";
import audition from "../../media/AppIcons/Audition.png";
import lmms from "../../media/AppIcons/LMMS.png";

import word from "../../media/AppIcons/Word.png";
import powerpoint from "../../media/AppIcons/Powerpoint.png";
import excel from "../../media/AppIcons/Excel.png";
import openOffice from "../../media/AppIcons/OpenOffice.png";

import inventor from "../../media/AppIcons/Inventor.png";
import fusion360 from "../../media/AppIcons/Fusion360.png";
import sketchUp from "../../media/AppIcons/SketchUp.png";
import libreCAD from "../../media/AppIcons/LibreCAD.png";

import premiere from "../../media/AppIcons/Premiere.png";
import davinciResolve from "../../media/AppIcons/DavinciResolve.png";
import hitFilmExpress from "../../media/AppIcons/HitFilmExpress.png";
import kdenlive from "../../media/AppIcons/Kdenlive.png";

const AppSlider = () => {
  return (
    <div className="appSlider">
      <Splide
        options={{
          direction: "ttb",
          pagination: false,
          perPage: 4,
          perMove: 1,
          height: "55rem",
          width: "32rem",
          focus: "center",
          type: "loop",
          gap: "1.2rem",
          autoplay: true,
          resetProgress: false,
          arrows: false,
        }}
      >
        <SplideSlide className="appSlider__section">
          <p className="appSlider__title">Graphic Design</p>
          <div className="appSlider__iconRow">
            <img
              src={inkscape}
              alt="inkscape logo"
              className="appSlider__icon"
            />
            <img
              src={illustrator}
              alt="adobe illustrator logo"
              className="appSlider__icon"
            />
            <img
              src={affinity}
              alt="affinity logo"
              className="appSlider__icon"
            />
          </div>
        </SplideSlide>
        <SplideSlide className="appSlider__section">
          <p className="appSlider__title">Music Production</p>
          <div className="appSlider__iconRow">
            <img
              src={musescore}
              alt="musescore logo"
              className="appSlider__icon"
            />
            <img
              src={flStudio}
              alt="fl studio logo"
              className="appSlider__icon"
            />
            <img
              src={audition}
              alt="adobe audition logo"
              className="appSlider__icon"
            />
            <img src={lmms} alt="lmms logo" className="appSlider__icon" />
          </div>
        </SplideSlide>
        <SplideSlide className="appSlider__section">
          <p className="appSlider__title">Office</p>
          <div className="appSlider__iconRow">
            <img src={word} alt="word logo" className="appSlider__icon" />
            <img
              src={powerpoint}
              alt="powerpoint logo"
              className="appSlider__icon"
            />
            <img src={excel} alt="excel logo" className="appSlider__icon" />
            <img
              src={openOffice}
              alt="open office logo"
              className="appSlider__icon"
            />
          </div>
        </SplideSlide>
        <SplideSlide className="appSlider__section">
          <p className="appSlider__title">CAD</p>
          <div className="appSlider__iconRow">
            <img
              src={inventor}
              alt="inventor logo"
              className="appSlider__icon"
            />
            <img
              src={fusion360}
              alt="fusion 360 logo"
              className="appSlider__icon"
            />
            <img
              src={sketchUp}
              alt="sketch up logo"
              className="appSlider__icon"
            />
            <img
              src={libreCAD}
              alt="libre cad logo"
              className="appSlider__icon"
            />
          </div>
        </SplideSlide>
        <SplideSlide className="appSlider__section">
          <p className="appSlider__title">Video Editing</p>
          <div className="appSlider__iconRow">
            <img
              src={premiere}
              alt="adobe premiere logo"
              className="appSlider__icon"
            />
            <img
              src={davinciResolve}
              alt="davinci resolve logo"
              className="appSlider__icon"
            />
            <img
              src={hitFilmExpress}
              alt="hit film logo"
              className="appSlider__icon"
            />
            <img
              src={kdenlive}
              alt="kdenlive cad logo"
              className="appSlider__icon"
            />
          </div>
        </SplideSlide>
      </Splide>
    </div>
  );
};

export default AppSlider;
