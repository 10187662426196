import React, { createContext, useState } from "react";

export const TaskbarContext = createContext();

const TaskbarContextProvider = (props) => {
  const [windowIsOpen, setWindowIsOpen] = useState(false);
  const [footerIsActive, setFooterIsActive] = useState(false);

  function windowHandler() {
    windowIsOpen ? setWindowIsOpen(false) : setWindowIsOpen(true);
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setFooterIsActive(true);
  //   }, 2000);
  //   return () => clearTimeout(timer);
  // });

  return (
    <TaskbarContext.Provider
      value={{
        windowIsOpen,
        windowHandler: windowHandler,
        setFooterIsActive,
        footerIsActive,
      }}
    >
      {props.children}
    </TaskbarContext.Provider>
  );
};

export default TaskbarContextProvider;
