import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import { auth, db, logout } from "../../firebase";
import { motion } from "framer-motion";
import NavBar from "../NavBar/NavBar";
import "./Dashboard.css";
import "./Auth.css";

function Dashboard() {
  //   const [user, loading, error] = useAuthState(auth);
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const history = useHistory();

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const query = await db
          .collection("users")
          .where("uid", "==", user?.uid)
          .get();
        const data = await query.docs[0].data();
        setName(data.name);
      } catch (err) {
        console.error(err);
        // alert("An error occurred while fetching user data");
      }
    };
    if (loading) return history.replace("/dashboard");
    if (!user) return history.replace("/login");
    fetchUserName();
  }, [user, loading, history]);

  return (
    <motion.div
      initial={{ opacity: 0.4 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.2 }}
      transition={{ ease: "easeOut", duration: 0.3 }}
      className="dashboard__wrapper"
    >
      <header>
        <NavBar />
      </header>
      <div className="authForm">
        <div className="dashboard authForm__container borderGradient borderGradient--purple">
          <p>Logged in as</p>
          <p>{name}</p>
          <p>{user?.email}</p>
          <button className="authForm__btn dashboard__btn" onClick={logout}>
            <p>Logout</p>
          </button>
          <Link to={"/desktop"}>
            <button className="authForm__btn authForm__submit dashboard__btn">
              <p>Go to Desktop</p>
            </button>
          </Link>
        </div>
      </div>
    </motion.div>
  );
}
export default Dashboard;
