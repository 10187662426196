import React from "react";
import "./GoogleLogin.css";
import { signInWithGoogle } from "../../firebase";
import GoogleLogo from "../../media/User Auth/btn_google_dark_normal_ios.svg";

const GoogleLogin = (props) => {
  return (
    <div
      className="googleLogin"
      role="button"
      onClick={signInWithGoogle}
      onKeyDown={signInWithGoogle}
    >
      <img src={GoogleLogo} alt="" className="googleLogin__logo" />
      <div class="googleLogin__label">{props.buttonText}</div>
    </div>
  );
};

export default GoogleLogin;
