import React, { useContext } from "react";
import { TaskbarContext } from "../../contexts/TaskbarContext.jsx";

// Media
import menuIcon from "../../../media/Desktop/menuIcon.svg";

const AppStoreToggle = () => {
  const { windowHandler } = useContext(TaskbarContext);

  return (
    <img
      className="desktop__icons--item"
      src={menuIcon}
      alt="App drawer button"
      onClick={windowHandler}
      onKeyDown={windowHandler}
    />
  );
};

export default AppStoreToggle;
