import React from "react";

const ProgramWindow = (props) => {
  console.log("iFrame Rendered");

  return (
    <div className="slider-frame">
      <iframe
        className="program-frame"
        title={props.name}
        width="100vw"
        height="100vh"
        src={props.url}
        frameBorder="0"
      >
        Program Loading...
      </iframe>
    </div>
  );
};

export default ProgramWindow;
