import React, { useContext } from "react";
import { motion } from "framer-motion";
import { TaskbarContext } from "../../contexts/TaskbarContext.jsx";

const Background = (props) => {
  const { windowIsOpen } = useContext(TaskbarContext);

  return (
    <div
      className={
        "desktop__title desktop__title--animate " +
        (!windowIsOpen ? "desktop__title--active" : "desktop__title--inactive")
      }
    >
      <div className="desktop__title-drag">
        <motion.h1 drag>S</motion.h1>
        <motion.h1 drag>e</motion.h1>
        <motion.h1 drag>c</motion.h1>
        <motion.h1 drag>o</motion.h1>
        <motion.h1 drag>n</motion.h1>
        <motion.h1 drag>d</motion.h1>
      </div>
      <div className="desktop__title-drag">
        <motion.h1 drag>D</motion.h1>
        <motion.h1 drag>e</motion.h1>
        <motion.h1 drag>s</motion.h1>
        <motion.h1 drag>k</motion.h1>
      </div>
      <hr className="hr--left" />
      <h4 className="desktop__title-sub">Your Program is Starting...</h4>
      <div className="loader">Loading...</div>
    </div>
  );
};

export default Background;
