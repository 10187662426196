import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth, signInUserWithEmailAndPassword } from "../../firebase";
import GoogleLogin from "./GoogleLogin";
import { useAuthState } from "react-firebase-hooks/auth";
import { motion } from "framer-motion";
import NavBar from "../NavBar/NavBar";
import "./Auth.css";
import "./Login.css";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //   const [user, loading, error] = useAuthState(auth);
  const [user, loading] = useAuthState(auth);
  const history = useHistory();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) history.replace("/dashboard");
  }, [user, loading, history]);
  return (
    <motion.div
      initial={{ opacity: 0.4 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.2 }}
      transition={{ ease: "easeOut", duration: 0.3 }}
      className="login__wrapper"
    >
      <header>
        <NavBar />
      </header>
      <div className="authForm">
        <motion.div
          initial={{ y: 80, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -80, opacity: 0 }}
          transition={{ ease: "easeOut", duration: 0.3 }}
          className="authForm__container borderGradient borderGradient--purple"
        >
          <h4 className="authForm__header">Login to Your Account</h4>
          <div className="googleBtn">
            <GoogleLogin buttonText="Sign in with Google" />
          </div>
          <label className="authForm__label" htmlFor="email-input">
            Email
          </label>
          <input
            id="email-input"
            type="text"
            className="authForm__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />
          <label className="authForm__label" htmlFor="password-input">
            Password
          </label>
          <input
            id="password"
            type="password"
            className="authForm__textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button
            className="authForm__btn authForm__submit"
            onClick={() => signInUserWithEmailAndPassword(email, password)}
          >
            Login
          </button>
          <div className="authForm__footer">
            <Link to="/reset">
              <p>Forgot Password</p>
            </Link>
          </div>
          <div>
            <p>
              Don't have an account? <Link to="/register">Register</Link> now.
            </p>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Login;
