import React from "react";
import AppCardCopy from "./AppCardCopy";

// Media
import InkscapeLogo from "../../media/AppStore/InkscapeLogo.png";
import GIMPLogo from "../../media/AppStore/GIMPLogo.png";
import MuseScoreLogo from "../../media/AppStore/MuseScoreLogo.png";

const AppList = (props) => {
  const APP_LIST = [
    {
      title: "Inkscape",
      icon: InkscapeLogo,
      label: "graphic-design",
      id: 1,
    },
    {
      title: "GIMP",
      icon: GIMPLogo,
      label: "photo-editing",
      id: 2,
    },
    {
      title: "MuseScore",
      icon: MuseScoreLogo,
      label: "music-prod",
      id: 3,
    },
    {
      title: "MuseScore",
      icon: MuseScoreLogo,
      label: "music-prod",
      id: 4,
    },
    {
      title: "MuseScore",
      icon: MuseScoreLogo,
      label: "music-prod",
      id: 5,
    },
  ];

  return (
    <div className="app-grid">
      {APP_LIST.map((appItem) => (
        <AppCardCopy
          title={appItem.title}
          icon={appItem.icon}
          key={appItem.id}
          label={appItem.label}
        />
      ))}
    </div>
  );
};

export default AppList;
