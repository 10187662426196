import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from "react-router-dom";
import { auth, registerUserWithEmailAndPassword } from "../../firebase";
import { motion } from "framer-motion";
import GoogleLogin from "./GoogleLogin";
import NavBar from "../NavBar/NavBar";
import "./Auth.css";
import "./Register.css";

const Register = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState("");
  //   const [user, loading, error] = useAuthState(auth);
  const [user, loading] = useAuthState(auth);
  const history = useHistory();
  const register = () => {
    if (!name) alert("Please enter name");
    registerUserWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user) history.replace("/dashboard");
  }, [user, loading, history]);
  return (
    <motion.div
      initial={{ opacity: 0.4 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.2 }}
      transition={{ ease: "easeOut", duration: 0.3 }}
      className="register__wrapper"
    >
      <header>
        <NavBar />
      </header>
      <div className="authForm">
        <motion.div
          initial={{ y: 80, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -80, opacity: 0 }}
          transition={{ ease: "easeOut", duration: 0.3 }}
          className="authForm__container borderGradient borderGradient--purple"
        >
          <h4 className="authForm__header">Create a New Account</h4>
          <div className="googleBtn">
            <GoogleLogin buttonText="Sign up with Google" />
          </div>
          <label className="authForm__label" htmlFor="name-input">
            Full Name
          </label>
          <input
            id="name-input"
            type="text"
            className="authForm__textBox"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Full Name"
          />
          <label className="authForm__label" htmlFor="email-input">
            Email
          </label>
          <input
            id="email-input"
            type="text"
            className="authForm__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
          />
          <label className="authForm__label" htmlFor="password-input">
            Password
          </label>
          <input
            id="password-input"
            type="password"
            className="authForm__textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button className="authForm__btn authForm__submit" onClick={register}>
            Create Account
          </button>
          <div className="authForm__footer">
            <p>
              Already have an account? <Link to="/login">Log In</Link>
            </p>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Register;
