import React, { useState } from "react";
import DesktopUI from "./UI/DesktopUI";
import ProgramContextProvider from "../contexts/ProgramContext";
import WelcomeScreen from "../Desktop/WelcomeScreen/WelcomeScreen";
import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'
import { Profiler } from "react";
import TaskbarContextProvider from "../contexts/TaskbarContext";
import NavBar from "../NavBar/NavBar";

const Desktop = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function closeHandler() {
    setModalIsOpen(true);
  }

  return (
    <div>
      <NavBar />
      <Protect boxTitle='You can request a demo from seconddeskteam@gmail.com' blur='true' sha512='7E745E34D718D05EFD7A6207553865382AF9A485C275CD892178ACC07986CA796CD81DAC6545331149FCC87A31D2E6288FF15C5F0B1CD6AD59AFDA0BE44D8691'>
      <div className="desktop-wrapper">
        <div className="desktop__loader desktop__loader--animate"></div>
        <Profiler id="desktop_profiler">
          <ProgramContextProvider>
            {!modalIsOpen && <WelcomeScreen onHide={closeHandler} />}
            <TaskbarContextProvider>
              {modalIsOpen && <DesktopUI />}
            </TaskbarContextProvider>
          </ProgramContextProvider>
        </Profiler>
      </div>
      </Protect>
    </div>
  );
};

export default Desktop;
